/* Estilos da barra de navegação */
.nav {
  /* Define a largura como 100% da largura visível da janela */
  width: 100vw;
  height: 80px;
  /* Alinha os itens horizontalmente e distribui o espaço igualmente entre eles */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Define a cor de fundo da barra de navegação */
  background-color: #18243A;
  /* Define o espaçamento interno superior e inferior para 0,4em */
  padding: 0.em 0;
}

/* Estilos para o logotipo na barra de navegação */
.nav img {
  /* Define a altura do logotipo como 35px */
  height: 35px;
  /* Define a margem esquerda como 20px para separar o logotipo dos links */
  margin-left: 20px;
}

/* Estilos para os links na barra de navegação */
.nav a {
  /* Remove o sublinhado dos links */
  text-decoration: none;
  /* Define o tamanho da fonte como 1.1rem */
  font-size: 1rem;
  /* Define a fonte como negrito */
  font-weight: bold;
  /* Define a cor do texto como branco */
  color: rgb(255, 255, 255);
  /* Define a cor de fundo dos links como verde escuro */
  background-color: rgb(0, 125, 32);
  /* Define a transição de 200ms para todos os estilos que mudam no hover */
  transition: all 200ms ease-in 0s;
  /* Define uma borda com 0,5px de espessura e totalmente transparente */
  border: 0.5px solid transparent;
  /* Define o espaçamento interno superior e inferior como 0,6em e as laterais como 1,6em */
  padding: 0.6em 1.6em;
  /* Define a margem direita como 20px para separar os links do logotipo */
  margin-right: 20px;
  /* Define o raio da borda como 10px para criar cantos arredondados */
  border-radius: 10px;
}

button.btnEnviar {
  /* Remove o sublinhado dos links */
  text-decoration: none;
  /* Define o tamanho da fonte como 1.1rem */
  font-size: 1.1rem;
  /* Define a fonte como negrito */
  font-weight: bold;
  /* Define a cor do texto como branco */
  color: rgb(255, 255, 255);
  /* Define a cor de fundo dos links como verde escuro */
  background-color: rgb(0, 125, 32);
  /* Define a transição de 200ms para todos os estilos que mudam no hover */
  transition: all 200ms ease-in 0s;
  /* Define uma borda com 0,5px de espessura e totalmente transparente */
  border: 0.5px solid transparent;
  /* Define o espaçamento interno superior e inferior como 0,6em e as laterais como 1,6em */
  padding: 0.6em 1.6em;
  /* Define o raio da borda como 10px para criar cantos arredondados */
  border-radius: 10px;
 
  cursor: pointer;

  margin-bottom: "-25px";
}

button.btnEnviar:hover {
  background-color: rgb(247, 247, 247); /* Define a nova cor de fundo para o hover */
  border-color: rgba(24, 36, 58, 0.103);
  color: rgb(0, 125, 32);

  }


/* Estilos para o hover dos links na barra de navegação */
.nav a:hover {
  /* Muda a cor de fundo para branco */
  background-color: rgb(255, 255, 255);
  /* Muda a cor do texto para verde escuro */
  color: rgb(0, 125, 32);
}

/* Responsividade para tablet com largura de até 768px */
@media (max-width: 768px) {
  /* Reduz a altura da barra de navegação */
  .nav {
    height: 80px;
  }

  /* Reduz a altura do logotipo */
  .nav img {
    height: 30px;
    /* Diminui a margem esquerda para acomodar o espaço reduzido */
    margin-left: 10px;
  }

  /* Reduz o espaçamento interno dos links */
  .nav a {
    padding: 0.4em 1em;
    margin-right: 10px;
    border-radius: 8px;
  }
}

/* Responsividade para dispositivos com largura de até 360px */
@media (max-width: 360px) {

  /* Reduz a altura do logotipo e altera a margem esquerda */
  .nav img {
    height: 25px; /* Define a altura do logotipo como 25px */
    margin-left: 5px; /* Define a margem esquerda como 5px */
  }

  /* Reduz o espaçamento interno e externo dos links */
  .nav a {
    padding: 0.3em 0.8em; /* Define o espaçamento interno superior e inferior como 0.3em e as laterais como 0.8em */
    margin-right: 5px; /* Define a margem direita como 5px */
    border-radius: 5px; /* Define o raio da borda como 5px para criar cantos arredondados */
    font-size: 0.9rem; /* Define o tamanho da fonte como 0.9rem */
  }
}

