.page-loader {
    position: fixed;
    gap: 32px;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #18243a;
  }
  
  .loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #e6e7e8;
  animation: spin 1.5s linear infinite;
}

  
  .loader-text {
    color: #e6e7e8;
    font-size: 24px;
  }
  
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  