.feedback-buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.feedback-buttons-icons {
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #18243A;
  color: #ffffff;
  border: none;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out; /* Adicionando transição suave no hover */
}

.feedback-buttons-icons:hover {
  background-color: rgb(0, 125, 32); /* Mudando a cor de fundo no hover */
  transform: scale(1.1); /* Adicionando animação de zoom no hover */
  animation: pulse 1s infinite; /* Adicionando animação de pulsação */
}

.ativo {
  background-color:rgb(0, 125, 32);
  transform: scale(1.1);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.feedback-buttons-icons button {
  background-color: transparent;
  color: inherit;
  border: none;
  cursor: pointer;
}

.feedback-buttons-icons button:focus {
  outline: none;
}

.emojix {
  font-size: 2em; /* Alterando o tamanho dos emojis para 2em */
  margin-bottom: 0.3em;
  transition: transform 0.2s ease-in-out; /* Adicionando transição suave no hover */
}

.feedback-buttons-icons:hover .emojix {
  transform: scale(1.2); /* Fazendo o emoji aumentar de tamanho no hover */
}

.textx {
  font-size: 1.2em;
}

/* Mobile */
@media only screen and (max-width: 600px) {
  .emojix {
    font-size: 1.9em; /* Alterando o tamanho dos emojis para 1.2em */
    margin-bottom: 0.2em;
  }

  .textx {
    font-size: 0.9em;
  }
}
