/* Base Styles */
.App {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  color: #18243A;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: hidden;
}

.centerContent {
  padding-bottom: calc(25vh - 140px);
}

.App h1 {
  margin-top: 1rem;
  margin-bottom: 0;
}

.contentTitle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.text2 {
  margin: 0;
  height: 2.5rem;
  text-align: center;
}

.container {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: -1em;
}

.container-label {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* divisor 
.divisor {
  height: 2px;
  background-color: #18243A;
  position: relative;
}

.divisor:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  width: 10px;
  height: 10px;
  background-color:#18243A;
  border-radius: 50%;
}

.divisor:after {
  content: "";
  position: absolute;
  top: -4px;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #18243A;
  border-radius: 50%;
}

@media screen and (min-width: 768px) {
  .divisor {
    margin: 0 calc(10vw + 40px);
  }
}

@media only screen and (max-width: 767px) {
  .divisor {
    margin: 0 5vw;
  }
}

@media only screen and (max-width: 600px) {
  .divisor {
    margin: 0 2.5vw;
  }
}

@media only screen and (max-width: 406px) {
  .divisor {
    margin: 0 1.5vw;
  }
}
 divisor */

.container-label span:last-child {
  text-transform: uppercase;
  width: 100%;
  height: 2.5rem;
  background-color: transparent;
  color: #18243A;
  border: none;
  padding: 2px;
  margin: 2px;
  border-radius: 5px;
  text-align: center;  
  font-size: 1.5rem;
  font-weight: bold;
}

.feedback-buttons {
  margin-bottom: 1.2rem;
}

footer {
  height: 2.5rem;
  width: 100vw;
  background-color: #18243A;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.feedback-text {
  font-size: 1rem;
  width: 260px;
  height: 100px;
}

/* Tablet Styles */
@media screen and (max-width: 768px) {
  .App {
    font-size: 1rem;
  }

  .feedback-buttons-icons {
    min-width: 7rem;
  }

  .emojix {
    font-size: 1.5rem;
    margin-bottom: .3em;
  }

  .textx {
    font-size: .9rem;
  }

  .subtextx {
    text-align: center;
  }

  .container-label span {
    width: 80%;
    padding: 8px;
    margin: 5px;
    font-size: 1.5rem;
  }
  
  footer {
    height: 3.5rem;
    text-align: center;
    font-size: .8rem;
  }
}

/* Mobile Styles */
@media only screen and (max-width: 600px) {
  .App {
    font-size: .8rem;
  }

  .App h1 {
    font-size: 2rem;
  }

  .emojix {
    font-size: 1rem;
    margin-bottom: .2em;
  }

  .textx {
    font-size: .8rem
  } 
}

@media only screen and (max-width: 375px) {
  .App h1 {
    font-size: 2rem;
    text-align: center;
  }
  
  .question {
    margin-bottom: 1em;
  }

  .container-label span {
    font-size: 1.1rem;
  }


}